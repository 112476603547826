<template>
  <!-- Generated using model-view-preview.mustache [ DO NOT EDIT GENERATED FILES DIRECTLY! ] -->
  <div class="competition-detail">
    <div class="form-content content">
      <ActionsForPreview
        :loading="loading"
        @edit="editCompetition"
        @delete="openModal"
        :isStartAction="startCompetitionAction"
        :isFinishAction="finishCompetitionAction"
        :isFinaliseAction="finaliseCompetitionAction"
        :isCancelAction="cancelCompetitionAction"
        :isExportEntrantsEnabled="true"
        @setStartStatus="handleStartCompetition"
        @setFinishStatus="handleFinishCompetition"
        @setFinaliseStatus="handleFinaliseCompetition"
        @setCancelStatus="handleCancelCompetition"
        @exportEntrants="openExportModal"
        :isDeleteEnabled="isDeleteEnabled"
      />
      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <ViewScheduling
          v-if="schedulingData"
          :schedulingData="schedulingData"
        />
        <ViewEntrants
          v-if="isViewEntrants"
          :dependantOnData="formData.entrantMemberTagsFilter"
          :constraints="formData.constraints"
        />
        <ViewRewards
          v-if="rewardModels.includes(model)"
          :entityId="entityId"
          :isCompetition="true"
          @goToContestsTab="goToContestsTab"
        />
        <ViewRules
          v-if="ruleModels.includes(model)"
          :entityId="entityId"
          @goToContestsTab="goToContestsTab"
        />
        <Products
          v-if="Object.keys(productsData).length"
          :productsData="productsData"
          :isPreview="true"
        />
        <ViewTranslations
          v-if="modelFields.baseFields.translatableFields.length > 0"
          :entityData="formData"
          :entityId="formData.id"
          :translatableFields="modelFields.baseFields.translatableFields"
        />
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteCompetition"
      v-on:toggle-modal="deleteModal = false"
    />
    <Modal
      :modalShow="exportModal"
      :messageGeneral="exportMessage"
      :title="texts.exportTitle"
      @doFunction="exportEntrants"
      v-on:toggle-modal="exportModal = false"
    />
    <Modal
      :modalShow="cloneModal"
      :messageGeneral="cloneMessage"
      :title="texts.cloneTitle"
      @doFunction="cloneContests"
      v-on:toggle-modal="closeCloneModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionsForPreview from '@/shared/components/ActionsForPreview';
import ZqFieldsView from '@/shared/components/ZqFieldsView';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { pageConfig } from '@/config';
import PreviewSpiner from '@/shared/UI/Spiner';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from '@/shared/UI/Modal';
import { competitionsTexts } from '@/config/pageTexts/competitions.json';
import fields from '@/generated/ziqni/store/modules/competitions/fields';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import ViewScheduling from '@/shared/components/supportModels/scheduling/ViewScheduling';
import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
import ViewEntrants from '@/shared/components/supportModels/entrants/ViewEntrants';
import Products from '@/shared/components/steps/components/Products';
import { cloneDeep } from 'lodash';
import axios from "axios";

export default {
  name: 'CompetitionDetails',
  components: {
    PreviewSpiner,
    ActionsForPreview,
    ZqFieldsView,
    Modal,
    ClCardArrow,
    ViewTranslations,
    ViewRules,
    ViewScheduling,
    ViewRewards,
    ViewEntrants,
    Products,
  },
  data() {
    return {
      model: 'competition',
      entityId: this.$route.params.id,
      formData: {
        id: '',
        spaceName: '',
        created: '',
        customFields: {},
        tags: [],
        metadata: {},
        numberOfRounds: '',
        numberOfGroupStages: '',
        name: '',
        description: '',
        termsAndConditions: '',
        maxNumberOfEntrants: '',
        minNumberOfEntrants: '',
        entrantMemberType: '',
        dependantOn: '',
        scheduledStartDate: '',
        scheduledEndDate: '',
        actualStartDate: '',
        actualEndDate: '',
        status: '',
        statusCode: '',
        constraints: [],
        products: [],
        icon: '',
        banner: '',
        bannerLowResolution: '',
        bannerHighResolution: ''
      },
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
          }
        },
      },
      texts: {
        ...competitionsTexts,
      },
      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      deleteModal: false,
      exportModal: false,
      exportMessage: '',
      cloneModal: false,
      cloneMessage: '',
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      modelFields : {
        ...fields,
      },
      ready: false,
      schedulingData: null,
      rewardModels: ['achievement', 'competition'],
      ruleModels: ['achievement', 'competition'],
      startCompetitionAction: false,
      finishCompetitionAction: false,
      finaliseCompetitionAction: false,
      cancelCompetitionAction: false,
      isDeleteEnabled: true
    };
  },
  computed: {
    ...mapGetters('competitions', [
      'success',
      'message',
      'competitions',
      'loading',
      'competition'
    ]),
    ...mapGetters('theme', ['theme']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('competitions', [
      'handleGetCompetitions_item',
      'handleUpdateCompetitionsState'
    ]),
    ...mapActions('contests', ['handleGetContestsByQuery']),
    ...mapActions('entrants', ['handleExportEntrantsByQuery']),
    ...mapActions('tags', ['handleGetTagsByQuery']),
    async initialize() {
      await this.handleGetCompetitions_item([this.$route.params.id],1,0).then(async (data) => {
        this.setActiveCompetitionActions(data[0]);

        routerBreadcrumbs(this.$router.currentRoute, {
          name: data[0].name,
        });

        this.$emit('competitionName', data[0].name);
        this.$emit('setCompetitionStatus', data[0].status);
        this.isDeleteEnabled = data[0].status !== 'Finished' && data[0].status !== 'Finalised';

        if (data[0].scheduling) {
          this.schedulingData = data[0].scheduling;
        }

        if (data[0].products && data[0].products.length) {
          this.productsData.selectedData = data[0].products;
          this.productsData.isAllProductsInclude = false;
        }

        if (data[0].productTagsFilter && Object.keys(data[0].productTagsFilter).length) {
          this.productsData.formData.shouldMatchAtLeast = data[0].productTagsFilter.shouldMatchAtLeast;
          this.productsData.formData.dependantOn.must = data[0].productTagsFilter.must ?? [];
          this.productsData.formData.dependantOn.should = data[0].productTagsFilter.should ?? [];
          this.productsData.formData.dependantOn.mustNot = data[0].productTagsFilter.mustNot ?? [];

          let tags = [
            ...this.productsData.formData.dependantOn.must,
            ...this.productsData.formData.dependantOn.should,
            ...this.productsData.formData.dependantOn.mustNot,
          ]

          this.productsData.selectedData = await this.handleGetTagsByQuery({
            queryRequest: {
              "must": [
                {
                  "queryField": "key",
                  "queryValues": tags
                }
              ]
            }
          })

          this.productsData.isAllProductsInclude = false;
        }

        this.ready = true;
      });

      if (this.$route.query.cloneId) {
        this.cloneModal = true;
      }
    },
    setActiveCompetitionActions(item) {
      switch (item.status) {
        case 'Ready':
          this.startCompetitionAction = true;
          this.cancelCompetitionAction = true;
          break
        case 'Active':
          this.finishCompetitionAction = true;
          this.cancelCompetitionAction = true;
          break
        case 'Finished':
          this.finaliseCompetitionAction = true;
          this.cancelCompetitionAction = true;
          break
      }
    },
    async handleStartCompetition() {
      const updateCompetitionStatusRequestArray = [{id: this.$route.params.id, status: 'Active'}];
      await this.handleUpdateCompetitionsState({updateCompetitionStatusRequestArray});
    },
    async handleFinishCompetition() {
      const updateCompetitionStatusRequestArray = [{id: this.$route.params.id, status: 'Finished'}];
      await this.handleUpdateCompetitionsState({updateCompetitionStatusRequestArray});
    },
    async handleFinaliseCompetition() {
      const updateCompetitionStatusRequestArray = [{id: this.$route.params.id, status: 'Finalised'}];
      await this.handleUpdateCompetitionsState({updateCompetitionStatusRequestArray});
    },
    async handleCancelCompetition() {
      const updateCompetitionStatusRequestArray = [{id: this.$route.params.id, status: 'Cancelled'}];
      await this.handleUpdateCompetitionsState({updateCompetitionStatusRequestArray});
    },
    isViewEntrants() {
      return this.formData.entrantMemberTagsFilter
        && Object.values(this.formData.entrantMemberTagsFilter).flat()
          .filter(e => typeof e === 'string' && e !== '').length
    },
    editCompetition() {
      this.$router.push({
        name: 'EditCompetition',
        params: {
          id: this.formData.id,
          name: this.formData.name,
        },
      });
    },
    async deleteCompetition() {
      this.deleteModal = false;
      const updateCompetitionStatusRequestArray = [{id: this.$route.params.id, status: 'Deleted'}];
      await this.handleUpdateCompetitionsState({updateCompetitionStatusRequestArray});
    },
    async exportEntrants() {
      this.exportModal = false;
      const exportData = await this.handleExportEntrantsByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'participationId',
              queryValues: [this.$route.params.id]
            }
          ]
        }
      });

      if (exportData.numberOfRecords > 0) {
        const token = localStorage.getItem('vue-token');
        const csv = await axios.get(exportData.downloadUrl, {
          headers: {"Authorization" : `Bearer ${token}`},
          responseType: 'text/csv'
        });
        const blob = new Blob([csv.data], {type : 'text/csv'});
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = this.formData.name + '_entrants';
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    openModal() {
      this.deleteModal = true;
    },
    closeCloneModal () {
      this.cloneModal = false;
      this.$router.replace({'query': null});
    },
    async cloneContests () {
      const contests = await this.handleGetContestsByQuery({
        queryRequest: {
          must: [
            {
              "queryField": "competitionId",
              "queryValues": [
                this.$route.query.cloneId
              ]
            }
          ],
          sortBy: this.sortBy,
          skip: 0,
          limit: 20,
        }
      });
      if (contests && contests.length) {
        const ids = contests.map(c => c.id);
        ids.reverse();
        const id = ids.shift();
        await this.$router.push({
          name: 'CloneContest',
          params: { cloneId: id },
          query: {
            cloneIds: encodeURIComponent(JSON.stringify(ids))
          }
        });
      }
    },
    async openExportModal() {
      const exportData = await this.handleExportEntrantsByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'participationId',
              queryValues: [this.$route.params.id]
            }
          ]
        }
      });

      this.exportMessage = `You are about to download ${exportData.numberOfRecords} rows of data`

      this.exportModal = true;
    },
    goToContestsTab(val) {
      this.$emit('updateActiveTab', val)
    }
  },
  watch: {
    competition: {
      deep: true,
      handler: function (val) {
        let competitionData = cloneDeep(val)
        delete competitionData.competitionType;
        if (competitionData.maxNumberOfEntrants > 99999) {
          competitionData.maxNumberOfEntrants = this.texts.entrants.maxNumberOfEntrantsPlaceholder
        }
        if (competitionData.minNumberOfEntrants === 0) {
          competitionData.minNumberOfEntrants = this.texts.entrants.maxNumberOfEntrantsPlaceholder
        }
        this.formData = competitionData;
      },
    },
    competitions: {
      deep: true,
      handler: function () {
        this.$router.push({ path: '/competitions' }).catch((e) => {
          console.log(e.message);
        });
      },
    },
  },
};
</script>
<style lang="scss">
competition-detail {
  height: 100%;
  flex: 2;
  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }
  .lang-custom-button {
    width: 100%;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>
